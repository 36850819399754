import type { FC, ReactNode } from 'react';
// import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import jwtDecode from 'jwt-decode';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    const decoded: any = jwtDecode(localStorage.getItem('accessToken'));
    window.location.href = `/admin/devices/${decoded?.deviceId}`;
    // return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
