import type { AppBarProps } from '@material-ui/core';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import { experimentalStyled, makeStyles } from '@material-ui/core/styles';
import type { FC } from 'react';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 170,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 28,
      justifyContent: 'space-between',
    },
    position: 'relative'
  },
  monitoring: {
    position: 'absolute',
    left: 300,
    top: 20,
    [theme.breakpoints.down('lg')]: {
      left: 80,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  avgProofSwitch: {
    position: 'absolute',
    left: 355,
    top: 14,
    [theme.breakpoints.down('lg')]: {
      left: 80,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  languageSelector: {
    marginLeft: theme.spacing(1),
    marginRight: '-20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      marginRight: '0 !important',
      '& button': {
        paddingRight: 0
      },
    },
  },
  notificationsPopover: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      '& button': {
        paddingRight: 0
      },
    },
  },
  searchWrapper: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      display: 'none !important'
    },
  },
}));

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => boolean;
  isSidebarMobileOpen?: boolean;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = () => {
  const classes = useStyles();

  return (
    <DashboardNavbarRoot>
      <Toolbar
        sx={{ minHeight: 64 }}
        className={classes.toolbar}
      >
        <Box className={classes.languageSelector}>
          <LanguagePopover />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
