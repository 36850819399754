import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  useTheme,
  useMediaQuery,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import jwtDecode from 'jwt-decode';
import { isUndefined, uniqBy } from 'lodash';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { deviceApi } from 'src/api/deviceApi';
import ChevronRight from 'src/icons/ChevronRight';
import Refresh from 'src/icons/Refresh';
import Html5QrcodePlugin from 'src/lib/Html5QrcodePlugin';
import logger from 'src/utils/logger';
import wait from 'src/utils/wait';
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  hiuAutocomplete: {
    marginTop: '48px'
  },
  lockIcon: {
    background: theme.palette.primary.main,
    width: 55,
    height: 55,
    padding: 14,
    borderRadius: 4,
    position: 'relative',
    top: '-25px',
    left: 49,
  },
  stepper: {
    marginTop: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .secondStep': {
        paddingLeft: 31,
        paddingTop: 11,
        [theme.breakpoints.down('md')]: {
          paddingLeft: 8,
        }
      }
    }
  },
  cantLogin: {
    marginTop: '1rem',
    [theme.breakpoints.down('md')]: {
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
      },
    }
  },
  card: {
    borderRadius: 8,
    overflow: 'unset',
    '& #region__dashboard_section_csr span': {
      [theme.breakpoints.down('md')]: {
        margin: '0 !important'
      },
    },
    '& .html5-qrcode-element': {
      [theme.breakpoints.down('md')]: {
        borderRadius: '8px !important',
      },
    },
    [theme.breakpoints.down('md')]: {
      // paddingLeft: 8,
      '& .MuiStepConnector-line': {
        width: '1px',
        height: '32px',
        marginLeft: '20px',
        marginTop: '10px',
        background: '#bdbdbd'
      },
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
    '& #html5qr-code-full-region': {
      marginBottom: '2rem',
      border: 'none !important',
      [theme.breakpoints.down('md')]: {
        marginBottom: '0 !important',
      },
    },
    '& #html5qr-code-full-region__scan_region': {
      marginTop: '2rem',
    },
    '& #html5qr-code-full-region__dashboard_section_csr': {
      [theme.breakpoints.down('md')]: {
        '& span[style]': {
          marginRight: '0 !important'
        },
      },
    },
    '& #html5qr-code-full-region__dashboard_section_csr button': {
      cursor: 'pointer',
      width: 290,
      height: 54,
      marginTop: 25,
      color: '#ffffff',
      backgroundColor: '#008C3C',
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      textTransform: 'uppercase',
      minWidth: '64px',
      padding: '6px 16px',
      borderRadius: '16px',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow: '0px 2px 4px rgb(0 0 0 / 15%), 0px 0px 0px 1px rgb(0 0 0 / 5%)',
      border: 'none',
      [theme.breakpoints.down('md')]: {
        borderRadius: '8px !important',
        width: '100%',
        marginBottom: 20,
        margin: '34px 0 20px !important',
        fontSize: '0.75rem',
        '& span[style]': {
          marginRight: '0 !important'
        },
      },
    },
    '& img[alt="Info icon"], #html5-qrcode-anchor-scan-type-change': {
      display: 'none !important'
    },
    '& #html5qr-code-full-region__dashboard_section_swaplink': {
      marginTop: 15,
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 48px 64px !important',
    [theme.breakpoints.down('sm')]: {
      padding: '0 48px 32px !important',
    }
  },
  textField: {
    borderRadius: 4,
  },
  submitButton: {
    borderRadius: 4,
    textTransform: 'uppercase',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginBottom: 15,
      width: '100%'
    }
  },
  forgotPassword: {
    color: theme.palette.warning.main,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }
  },
  error: {
    borderRadius: 4,
  },
  scanButton: {
    margin: '48px 0 20px',
    width: '100%',
    height: 56,
    borderRadius: 4,
    textTransform: 'unset',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 20,
      margin: '34px 0 20px !important',
    },
  },
}));

const LoginJWT: FC = () => {
  const classes = useStyles();
  const { login } = useAuth() as any;
  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);
  const [articleNumber, setArticleNumber] = useState<string>('');
  const [hiuOptions, setHIUOptions] = useState<any>([]);
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [showQRScanner, setShowQRScanner] = useState<boolean>(false);
  const [showHIUQRScanner, setShowHIUQRScanner] = useState<boolean>(false);
  const [displayHIUDropdown, showDisplayHIUDropdown] = useState<boolean>(false);
  const [displaySerialNumberField, setDisplaySerialNumberField] = useState<boolean>(false);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true
  });

  const [activeStep, setActiveStep] = useState(0);
  // const [progress, setProgress] = useState(0);
  const steps = [t('service_portal_login_in_serial_number_scan_button'),
    t('service_portal_login_in_heat_interface_unit_scan_button')];

  const handleHIUDropdownChange = async () => {
    showDisplayHIUDropdown(!displayHIUDropdown);
  };

  const handleDisplaySerialNumberChange = async () => {
    setDisplaySerialNumberField(!displaySerialNumberField);
  };

  const onNewQRScanResult = async (decodedText: string) => {
    const qrSerialNumber = decodedText?.split('_');
    if (qrSerialNumber?.length !== 2) {
      const errorMsg = t('service_portal_login_in_correct_qr_code');
      toast.error(errorMsg);
    }
    if (qrSerialNumber?.length === 2) {
      if (qrSerialNumber[0]?.length < 24 || qrSerialNumber[0]?.length > 24) {
        const errorMsg = t('service_portal_login_in_correct_qr_code');
        toast.error(errorMsg);
      } else {
        setSerialNumber(qrSerialNumber[0]);
        setActiveStep(1);
      }
    } else {
      setSerialNumber(qrSerialNumber[0]);
      setActiveStep(1);
    }

    setShowQRScanner(false);
  };

  const onTextResult = async (qrSerialNumber: string) => {
    if (qrSerialNumber?.length < 24 || qrSerialNumber?.length > 24) {
      const errorMsg = t('service_portal_login_in_correct_qr_code');
      toast.error(errorMsg);
    } else {
      setSerialNumber(qrSerialNumber?.toLowerCase());
      setActiveStep(1);
    }

    setShowQRScanner(false);
  };

  const loginInstaller = async () => {
    if (articleNumber?.length > 0 && serialNumber?.length > 0) {
      try {
        await login(serialNumber?.toLowerCase(), serialNumber?.toLowerCase());
        const decoded: any = jwtDecode(localStorage.getItem('accessToken'));
        window.location.href = `/admin/devices/${decoded?.deviceId}`;
      } catch (err) {
        logger(err);
        const errorMsg = (err.response.status === 400 || err.response.status === 401) ? t('service_portal_login_not_a_registered_service_device') : t('devices_form_api_error');
        toast.error(errorMsg);
        await wait(4000);
        // localStorage.clear();
      }
    }
  };

  const onNewHIUQRScanResult = async (decodedText) => {
    const heatUnitInterfaceParts = decodedText?.split('|');
    // setHeatUnitInterface(heatUnitInterfaceParts);
    if (heatUnitInterfaceParts?.length > 0 && !isUndefined(heatUnitInterfaceParts[2])) {
      setArticleNumber(heatUnitInterfaceParts[2]);
      localStorage.setItem('article_number', heatUnitInterfaceParts[2]);
    }
    setShowHIUQRScanner(false);
  };

  // const onQrCodeError = (decodedText) => {
  //   const qrSerialNumber = decodedText?.split('_');
  //   if (qrSerialNumber?.length > 0) {
  //     const errorMsg = t('service_portal_login_in_correct_qr_code');
  //     toast.error(errorMsg);
  //     setShowQRScanner(false);
  //   }
  // };

  const handleQRScannerButton = () => {
    setShowQRScanner(true);
  };

  const handleHIUQRScannerButton = () => {
    setShowHIUQRScanner(true);
  };

  const handleSerialNumberChange = (e) => {
    setSerialNumber(e?.target?.value);
  };

  useEffect(() => {
    loginInstaller();
  }, [articleNumber, serialNumber]);

  const handleHIUChange = (e: any) => {
    setArticleNumber(e?.target?.value);
    localStorage.setItem('article_number', e?.target?.value);
  };

  const getAllHIUOptions = async () => {
    try {
      const res: any = await deviceApi.getAllArticleNumbers();
      let formattedResponse = res?.map((item) => ({ name: item?.name, value: item?.name }));
      formattedResponse = uniqBy(formattedResponse, 'name');
      setHIUOptions(formattedResponse);
    } catch (err) {
      logger(err);
      setHIUOptions([]);
    }
  };

  useEffect(() => {
    getAllHIUOptions();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center'
      }}
    >
      <Container
        maxWidth="sm"
        sx={{ p: '0 !important' }}
      >
        <Card className={classes.card}>
          <svg
            className={classes.lockIcon}
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.5625 9.125H15.625V6.3125C15.625 3.21875 13.0938 0.6875 10 0.6875C6.90625 0.6875 4.375 3.21875 4.375 6.3125V9.125H3.4375C1.84375 9.125 0.625 10.3438 0.625 11.9375V18.5C0.625 20.0938 1.84375 21.3125 3.4375 21.3125H16.5625C18.1562 21.3125 19.375 20.0938 19.375 18.5V11.9375C19.375 10.3438 18.1562 9.125 16.5625 9.125ZM6.25 6.3125C6.25 4.25 7.9375 2.5625 10 2.5625C12.0625 2.5625 13.75 4.25 13.75 6.3125V9.125H6.25V6.3125ZM16.5625 19.4375C17.125 19.4375 17.5 19.0625 17.5 18.5V11.9375C17.5 11.375 17.125 11 16.5625 11H3.4375C2.875 11 2.5 11.375 2.5 11.9375V18.5C2.5 19.0625 2.875 19.4375 3.4375 19.4375H16.5625Z"
              fill="white"
            />
          </svg>
          <CardContent
            className={classes.cardContent}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2
              }}
            >
              <div>
                <Typography
                  color="textPrimary"
                  variant="h4"
                >
                  {t('login_sign_in')}
                </Typography>
              </div>
            </Box>
            <Grid
              item
              xs={12}
            >
              <Divider />
            </Grid>
            <Box
              sx={{
                flexGrow: 1,
                mt: 2
              }}
            >
              <form
                noValidate
                id="login_form"
              >

                <Stepper
                  activeStep={activeStep}
                  className={classes.stepper}
                >
                  {steps?.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};
                    return (
                      <Step
                        key={label}
                        className={clsx({
                          secondStep: index === 1
                        })}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>

                {(activeStep === 0 && showQRScanner && !displaySerialNumberField && serialNumber?.length === 0) && (
                  <Html5QrcodePlugin
                    fps={15}
                    qrbox={isSm ? 160 : 320}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewQRScanResult}
                    // qrCodeErrorCallback={onQrCodeError}
                  />
                )}
                {(activeStep === 0 && !showQRScanner && !displaySerialNumberField && serialNumber?.length === 0) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleQRScannerButton}
                    className={classes.scanButton}
                  >
                    {t('service_portal_login_in_serial_number_scan_button')}
                    <Refresh
                      sx={{ ml: 2 }}
                    />
                  </Button>
                )}
                {(!showQRScanner && serialNumber?.length === 24) && (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    sx={{ marginTop: '1.85rem' }}
                  >
                    {t('service_portal_login_in_serial_number_scanned_message')}
                  </Typography>
                )}

                {(activeStep === 1 && !displayHIUDropdown && showHIUQRScanner && articleNumber?.length === 0) && (
                  <Html5QrcodePlugin
                    fps={15}
                    qrbox={isSm ? 160 : 320}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewHIUQRScanResult}
                    // qrCodeErrorCallback={onQrCodeError}
                  />
                )}
                {(activeStep === 1 && !displayHIUDropdown && !showHIUQRScanner && articleNumber?.length === 0) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleHIUQRScannerButton}
                    className={classes.scanButton}
                  >
                    {t('service_portal_login_in_heat_interface_unit_scan_button')}
                    <Refresh
                      sx={{ ml: 2 }}
                    />
                  </Button>
                )}

                {(activeStep === 0 && displaySerialNumberField) && (
                  <Box>
                    <TextField
                      className={classes.scanButton}
                      fullWidth
                      label={t('service_portal_login_serial_number_field')}
                      placeholder={t('service_portal_login_serial_number_field')}
                      onChange={(e) => handleSerialNumberChange(e)}
                      value={serialNumber || ''}
                      InputProps={{
                        classes: {
                          root: classes.textField
                        },
                      }}
                    />
                    <i
                      style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}
                    >
                      {t('service_portal_login_serial_number_field_example')}
                    </i>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => onTextResult(serialNumber)}
                      className={classes.scanButton}
                    >
                      {t('service_portal_login_check_serial_number_button')}
                      <ChevronRight
                        sx={{ ml: 2 }}
                      />
                    </Button>
                  </Box>
                )}
                {activeStep === 0 && (
                  <FormControlLabel
                    className={classes.cantLogin}
                    control={(
                      <Checkbox
                        color="primary"
                        checked={displaySerialNumberField}
                        name="is_service_device"
                        value={displaySerialNumberField}
                        onChange={handleDisplaySerialNumberChange}
                      />
                    )}
                    label={t('service_portal_login_in_cant_login_serial_number_checkbox_field')}
                  />
                )}

                {(activeStep === 1 && displayHIUDropdown && articleNumber?.length === 0) && (
                  <TextField
                    id="operations-dropdown"
                    select
                    label={t('service_portal_login_hiu_autocomplete')}
                    value={articleNumber || ''}
                    className={classes.scanButton}
                    onChange={(e) => handleHIUChange(e)}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    <option
                      key="123asasas21asds11dsad3dsaxasdasdsa2dwsdd"
                      value="-1"
                    >
                      {t('service_portal_login_hiu_autocomplete_default_option')}
                    </option>
                    {hiuOptions?.map((option) => (
                      <option
                        key={option?.name}
                        value={option?.name}
                      >
                        {option?.name}
                      </option>
                    ))}
                  </TextField>
                )}

                {activeStep === 1 && (
                  <FormControlLabel
                    sx={{ marginTop: '1rem' }}
                    control={(
                      <Checkbox
                        color="primary"
                        checked={displayHIUDropdown}
                        name="is_service_device"
                        value={displayHIUDropdown}
                        onChange={handleHIUDropdownChange}
                      />
                    )}
                    label={t('service_portal_login_in_dont_have_hiu_qr_code_checkbox_field')}
                  />
                )}

                {(!showHIUQRScanner && articleNumber?.length > 0) && (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    sx={{ marginTop: '1.85rem' }}
                  >
                    {t('service_portal_login_in_heat_interface_unit_scanned_message')}
                  </Typography>
                )}
              </form>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default LoginJWT;
