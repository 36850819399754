// import { isUndefined } from 'lodash';
import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const NoContentFound = Loadable(lazy(() => import('./pages/NoContentFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Device Pages
const DeviceSingleDeviceView = Loadable(lazy(() => import('./pages/device/DeviceSingleDeviceView')));
const DeviceEditView = Loadable(lazy(() => import('./pages/device/DeviceEditView')));

// react-flow
const Flow = Loadable(lazy(() => import('./pages/Flow')));

// eslint-disable-next-line import/no-mutable-exports
const routes: PartialRouteObject[] = [
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'flow',
        element: <Flow />
      },
      {
        path: 'devices',
        children: [
          {
            path: ':deviceId',
            element: <DeviceSingleDeviceView />
          },
          {
            path: 'edit/:deviceId',
            element: <DeviceEditView />
          },
        ]
      },
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '/login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: 'not-found',
        element: <NoContentFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
